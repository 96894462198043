import React, { Fragment } from 'react'
import Page from '../../components/page'
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
} from '@material-ui/core'

import Stepper from '../../components/stepper'

import selectpaid from '../../static/buy/selectpaid.png'
import cart from '../../static/buy/cart.png'
import checkout from '../../static/buy/checkout.png'
import success from '../../static/buy/success.png'
import install05 from '../../static/fitface-install/05.jpg'
import install06 from '../../static/fitface-install/06.jpg'
import install07 from '../../static/fitface-install/07.jpg'

const styles = theme => ({
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
})

@withRoot
@withStyles(styles)
export default class BuyDesign extends React.Component {
  render() {
    const { classes } = this.props

    const STEPS = [
      {
        label: 'Select a paid design from the gallery',
        content: (
          <Fragment>
            <Typography>Just click on the <b>+</b> button then <b>Add to cart</b> to select the design.</Typography>
            <Typography>A message will confirm the design has been added in your shopping cart.</Typography>
          </Fragment>
        ),
        img: selectpaid
      },
      {
        label: 'Continue your shopping',
        content: (
          <Typography>You can add as much designs as you want in your shopping cart.</Typography>
        ),
        img: ''
      },
      {
        label: 'Proceed to checkout',
        content: (
          <Typography>Click on the <b>Shopping cart</b> button at the top right.</Typography>
        ),
        img: cart
      },
      {
        label: 'Select your payment method',
        content: (
          <Fragment>
            <Typography>Follow the PayPal instructions for the payment.</Typography>
            <Typography>You can use your PayPal account or a credit card to make the purchase.</Typography>
          </Fragment>
        ),
        img: checkout
      },
      {
        label: 'Congrats, you\'ve made a purchase',
        content: (
          <Fragment>
            <Typography>You will receive a confirmation email from PayPal.</Typography>
            <Typography>We display a success screen with the procedure to install the design on your watch (described here after).</Typography>
          </Fragment>
        ),
        img: success
      },
      { label: 'Open the settings of the FitFace clockface in the Fitbit app on your phone', content: '', img: '' },
      {
        label: 'Touch "Select another design"',
        content: '',
        img: install05
      },
      {
        label: 'Touch "My Purchases"',
        content: (
          <Fragment>
            <Typography>This collection contains all your purchases.</Typography>
            <Typography>You may have to click on the refresh the lists button to udpate the lists with your latest purchase.</Typography>
          </Fragment>
        ),
        img: install07
      },
      {
        label: 'Select your purchase',
        content: (
          <Fragment>
            <Typography>You will feel a small vibration, a loading screen will appear on your watch and phone.</Typography>
            <Typography>And after a few seconds, you will be able to enjoy the design on your wrist!</Typography>
          </Fragment>
        ),
        img: install06
      },
      {
        label: 'Your design is now installed. Enjoy!',
        content: (
          <Fragment>
            <Typography>Your purchase is linked with your account.</Typography>
            <Typography>If you uninstall FitFace, just login back and you&apos;ll find all your past purchases.</Typography>
          </Fragment>
        ),
        img: ''
      },
    ]

    return (
      <Page toggleTheme={this.props.toggleTheme} title="How to buy a design?">
        <Typography className={classes.parag}>
          Some designs in the FitFace gallery require payment. Here is the procedure to buy them.
        </Typography>
        <Stepper steps={STEPS} />
      </Page>
    )
  }
}
